import axios, { AxiosInstance } from 'axios';

export default {
    httpClient: (timeout:number = 200000 ) : AxiosInstance => {
       return axios.create({
            baseURL: process.env.VUE_APP_BACKEND_API,
            timeout: timeout,
            headers: {
                "Content-Type": "application/json",
            }
        }
      )}
}