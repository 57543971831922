import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppLayout from "../layout/app-layout.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/payment",
    name: "payment",
    component: AppLayout,
    children: [
      {
        name: "payment-success",
        path: "success/:idOrder",
        component: () => import("../views/SuccessView.vue"),
      },
      {
        name: "payment-cancel",
        path: "cancel/:idOrder",
        component: () => import("../views/CancelView.vue"),
      },
      {
        name: "contact-manager",
        path: "contact_manager",
        component: () => import("../views/ContactManagerView.vue"),
      },
    ],
  },
  {
    path: "/order",
    name: "home",
    component: AppLayout,
    children: [
      {
        name: "homeview",
        path: ":idRestaurant?/:idTable?/:disabledAtPlace?",
        component: () => import("../views/HomeView.vue"),
      },
    ],
  },
  {
    path: "/restaurant",
    name: "homeRestaurant",
    component: AppLayout,
    children: [
      {
        name: "homeRestaurantView",
        path: ":idRestaurant?/:idTable?/:disabledAtPlace?",
        component: () => import("../views/HomeView.vue"),
        props: { default: true, sidebar: false },
        meta: {
          type: "slug",
        },
      },
      {
        name: "homeRestaurantwithConfiguration",
        path: ":idRestaurant?/options/:atPlace/:takeOut/:delivery",
        component: () => import("../views/HomeView.vue"),
        props: { default: true, sidebar: false, includeOptions: true },
        meta: {
          type: "slug",
          includeOptions: true
        },
      },
    ],
  },
  {
    path: "/confirm/:success?",
    name: "confirm",
    component: AppLayout,
    children: [
      {
        name: "confirmview",
        path: "",
        component: () => import("../views/ConfirmView.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: AppLayout,
    children: [
      {
        name: "errorview",
        path: "404",
        component: () => import("../views/ErrorView.vue"),
      },
    ],
  },
  {
    path: "/scan",
    name: "scan",
    component: AppLayout,
    children: [
      {
        name: "again",
        path: "again",
        component: () => import("../views/ScanAgain.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    redirect: { name: "errorview" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
