import { createStore } from "vuex";


import cart from '@/store/modules/cart/index';
import restaurant from '@/store/modules/restaurant';
import products from '@/store/modules/products';
const store = createStore({
  modules: {
    cart,
    restaurant,
    products
  },
});

export default store;
