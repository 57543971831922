<template>

<body data-spy="scroll" data-bs-target="#secondary_nav" data-offset="75">
    
      <Navbar />
    <!-- /header -->

    
  <router-view />


  </body>

 
</template>

<script>

import Navbar from '../components/Navbar.vue'
export default {
  name: 'app-layout',
  components: {
    Navbar,
    
  }
}
</script>
<style scoped>
.v-images-wrap{
  z-index: 99999999 !important;
}
</style>

